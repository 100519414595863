/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentRef, useEffect, useMemo, useRef, useState } from 'react';
import { Training } from '../../models/Training';
import TrainingService from '../../services/TrainingService';
import { useCurrentClient } from '../../global-state/Clients';
import { Link, useNavigate } from 'react-router-dom';
import Accordion from '../../components/shared/accordion/Accordion';
import Button, { ButtonSize, ButtonType, iconButtonClasses, iconButtonContainerClasses } from '../../components/shared/form-control/Button';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../components/shared/Tooltip';
import { InputStyle, Input } from '../../components/shared/form-control/Input';
import FunnelIcon from '../../components/shared/icon/FunnelIcon';
import SearchIcon from '../../components/shared/icon/SearchIcon';
import SortIcon from '../../components/shared/icon/SortIcon';
import Popover from '../../components/shared/popover/Popover';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import { SortingState, ColumnFiltersState } from '@tanstack/react-table';
import DownloadIcon from '../../components/shared/icon/DownloadIcon';
import DataTable from '../../components/shared/data-grid/DataTable';
import { ApiResponse } from '../../models/ApiResponse';
import EmptyEventsSvg from '../../assets/images/empty-training-events.svg';
import EmptyTrainingSvg from '../../assets/images/empty-training.svg';
import { SearchInput } from '../../components/shared/form-control/SearchInput';
import ContextMenu from '../../components/shared/ContextMenu';

const TrainingLibrary = () => {
  const [trainings, setTrainings] = useState<Training[]>([]);
  const [trainingEvents, setTrainingEvents] = useState<string[]>([]);
  const client = useCurrentClient((x) => x.value);
  const leftPaneRef = useRef<HTMLDivElement>(null);
  const [leftNavCollapsed, setLeftNavCollapsed] = useState(false);
  const [searchTrainingTerm, setSearchTrainingTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const totalFilters = useMemo(() => columnFilters.length, [columnFilters]);
  const totalSorts = useMemo(() => sorting.length, [sorting]);
  const filterColumnPopoverRef = useRef<Record<string, ComponentRef<typeof Popover>>>({});
  const navigate = useNavigate();
  const { t } = useTranslation(['training', 'table-view']);
  useEffect(() => {
    if (!client) return;
    TrainingService.getTrainingPaged(client?.id).then((response) => {
      setTrainings(response.data);
    });
  }, [client]);

  const columns = useMemo(() => {
    return [
      {
        header: 'Name',
        accessorKey: 'eventTitle',
        id: 'eventTitle',
        enableSorting: true,
      },
      {
        header: 'Training',
        accessorKey: 'trainingTitle',
        id: 'trainingTitle',
        enableSorting: true,
      },
      {
        header: 'Trainer',
        accessorKey: 'trainer',
        id: 'trainer',
        enableSorting: true,
      },
      {
        header: 'Trainees',
        accessorKey: 'trainees',
        id: 'trainees',
        enableSorting: true,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        id: 'status',
        enableSorting: true,
      },
      {
        header: 'Due',
        accessorKey: 'dueUtc',
        id: 'dueUtc',
        enableSorting: true,
      },
    ];
  }, []);

  return (
    <div className="flex h-full min-h-full flex-col">
      <TopNavPortal>
        <Heading size={HeadingSize.H2} actualSize={HeadingSize.H3}>
          {t('training-heading')}
        </Heading>
      </TopNavPortal>
      <div className="flex h-full flex-col">
        <div className="relative flex flex-1 overflow-y-auto overflow-x-hidden pr-1">
          <div>
            <div className="relative h-full">
              <div
                ref={leftPaneRef}
                className={`sticky top-0 h-full w-[300px] overflow-y-auto border-r pb-4 transition-[margin] duration-300 ${
                  leftNavCollapsed ? '-ml-[285px]' : 'mr-0'
                }`}
              >
                <Accordion title="Training Library" active>
                  {trainings.length > 0 && (
                    <>
                      <SearchInput
                        placeholder="Search"
                        value={searchTrainingTerm}
                        style={InputStyle.MINIMAL}
                        onChange={(e) => setSearchTrainingTerm(e.target.value)}
                      />
                      <ul className="mt-2">
                        {trainings.map((training) => (
                          <li
                            key={training.id}
                            className="text-gray-2 hover:bg-primary-2 group flex items-center justify-between p-1 hover:bg-opacity-10"
                          >
                            <Link to={`/clients/${client?.id}/training/${training.id}`}>{training.title}</Link>
                            <ContextMenu
                              className="opacity-0 group-hover:opacity-100"
                              size={ButtonSize.S}
                              items={[
                                {
                                  title: t('training-lib.training-context-menu.add-event'),
                                  onClick: () => {
                                    throw new Error('Function not implemented.');
                                  },
                                },
                                {
                                  title: t('training-lib.training-context-menu.archive'),
                                  onClick: () => {
                                    throw new Error('Function not implemented.');
                                  },
                                },
                              ]}
                            />
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {trainings.length === 0 && <div className="text-gray-2 text-dpm-14">{t('training-lib.empty.title')}</div>}
                </Accordion>
              </div>
            </div>
          </div>
          <div className={`bg-background-1 flex w-full flex-col overflow-y-auto px-6 py-2`}>
            <div className="mt-1 py-2">
              <div className="flex w-full items-center justify-between">
                <div className="w-full">
                  <Heading size={HeadingSize.H3} actualSize={HeadingSize.H4}>
                    {t('training-library')}
                  </Heading>
                </div>
                <div className="flex gap-2">
                  <Button type={ButtonType.PRIMARY} size={ButtonSize.S} onClick={() => navigate(`/clients/${client?.id}/training/create`)}>
                    Create Training
                  </Button>
                </div>
              </div>
            </div>
            {trainingEvents.length > 0 && (
              <div className="mt-4 flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Heading size={HeadingSize.H6} className="font-medium">
                    {t('training-lib.sub-heading')}
                  </Heading>
                  <span className="text-gray-4 mt-[2px] font-bold"> • </span>
                  <span className="text-dpm-14 mt-[4px]">{t('training-lib.results-count', { count: 0, total: 0 })}</span>
                </div>

                <div className={`flex flex-shrink-0 items-center gap-2 pr-1`}>
                  <div className="flex items-center">
                    <div
                      className={`overflow-hidden transition-all duration-300 ease-in-out ${isSearchExpanded ? 'mr-2 w-64 px-1 opacity-100' : 'mr-0 w-0 px-0 opacity-0'} `}
                    >
                      <Input
                        style={InputStyle.MINIMAL}
                        placeholder={t('training-lib.search')}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClear={() => setSearchTerm('')}
                        autoFocus={isSearchExpanded}
                      />
                    </div>
                    <Tooltip text={t('training-lib.tooltips.search')}>
                      {(tooltip) => (
                        <div {...tooltip}>
                          {
                            <SearchIcon
                              className={`${iconButtonClasses} ${searchTerm.length > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
                              onClick={() => setIsSearchExpanded((prev) => !prev)}
                            />
                          }
                        </div>
                      )}
                    </Tooltip>
                  </div>

                  <div className={`flex flex-shrink-0 items-center gap-2 !pr-1`}>
                    <Tooltip text={t('events.tooltips.download')}>
                      {(tooltip) => (
                        <div {...tooltip}>
                          {<DownloadIcon className={`${iconButtonClasses}`} onClick={() => setIsSearchExpanded((prev) => !prev)} />}
                        </div>
                      )}
                    </Tooltip>
                    <Popover ref={(ref) => (filterColumnPopoverRef.current['filtering'] = ref!)} content={<></>} placement="bottom-end">
                      {(popover, toggle) => (
                        <div
                          {...popover}
                          className={`${iconButtonContainerClasses} ${totalFilters > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
                          {...mouseAndKeyboardCallbackProps(toggle)}
                        >
                          <Tooltip text={t('training-lib.tooltips.filter')}>
                            {(tooltip) => <div {...tooltip}>{<FunnelIcon className="h-6 w-5" />}</div>}
                          </Tooltip>
                          {totalFilters > 0 && <div className="px-2 font-medium">{t('table-view:filters.count', { count: totalFilters })}</div>}
                        </div>
                      )}
                    </Popover>

                    <Popover content={<></>} placement="bottom-end">
                      {(popover, toggle) => (
                        <div
                          {...popover}
                          className={`${iconButtonContainerClasses} ${totalSorts > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
                          {...mouseAndKeyboardCallbackProps(toggle)}
                        >
                          <Tooltip text={t('training-lib.tooltips.sorting')}>
                            {(tooltip) => <div {...tooltip}>{<SortIcon className="h-6 w-5" />}</div>}
                          </Tooltip>
                          {totalSorts > 0 && <div className="px-2 font-medium">{t('table-view:sorting.count', { count: totalSorts })}</div>}
                        </div>
                      )}
                    </Popover>
                  </div>
                </div>
              </div>
            )}
            <div className="-mx-6 flex h-full flex-1 flex-col overflow-hidden p-6">
              {(trainings.length === 0 || trainingEvents.length === 0) && (
                <div className="flex h-full flex-col items-center justify-center gap-6 text-center">
                  <img src={EmptyTrainingSvg} alt={t('training-lib.empty.title')} className="max-w-[125px]" />
                  <div>
                    <Heading size={HeadingSize.H3} textColor="text-black" className="my-2">
                      {t('training-lib.empty.title')}
                    </Heading>
                    <div className="text-gray-2">{t('training-lib.empty.description')}</div>
                  </div>

                  <div className="flex items-center justify-center gap-2">
                    <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={() => navigate(`/clients/${client?.id}/training/create`)}>
                      {t('training-lib.buttons.create-training')}
                    </Button>
                    {trainings.length > 0 && trainingEvents.length === 0 && (
                      <Button size={ButtonSize.S} type={ButtonType.SECONDARY} onClick={() => navigate(`/clients/${client?.id}/training/create`)}>
                        {t('training-lib.buttons.create-event')}
                      </Button>
                    )}
                  </div>
                </div>
              )}
              {trainingEvents.length > 0 && (
                <DataTable
                  dataId={''}
                  columns={columns}
                  queryFn={function (filter: any): Promise<ApiResponse<any>> {
                    return Promise.resolve({ data: [], total: 0, meta: { success: true, code: 200, message: '' }, status: 200, headers: {} });
                  }}
                  title={''}
                  searchTerm="na"
                >
                  <DataTable.Slot name="Empty">
                    <div data-cy="list-empty" className="mt-20 flex h-full w-full items-center justify-center bg-white">
                      <div className="flex w-full flex-col items-center justify-center gap-6 text-center">
                        <img src={EmptyEventsSvg} alt={t('events.empty')} className="max-w-[145px]" />
                        <div>
                          <div className="text-gray-2">{t('events.empty')}</div>
                        </div>
                      </div>
                    </div>
                  </DataTable.Slot>
                </DataTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingLibrary;
